import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.svg";
import projImg2 from "../assets/img/project-img2.svg";
import projImg4 from "../assets/img/project-img4.svg";
import projImg5 from "../assets/img/project-img5.svg";
import projImg6 from "../assets/img/project-img6.svg";
import projImg7 from "../assets/img/project-img7.svg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import '../App.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';
import React from 'react';
import 'reactjs-popup/dist/index.css';
import contactPopup1 from "../assets/img/project-popup1.svg";
import contactPopup2 from "../assets/img/project-popup2.svg";
import contactPopup3 from "../assets/img/project-popup3.svg";

export const Projects = () => {
  const { t, i18n } = useTranslation();

  const projects = [
    {
      title: "TmyPacs Workstation",
      imgUrl: contactPopup2,
      popImg: contactPopup2,
      popText: "Tmy Workstation sağlık alanında yeni bir seviyeye ulaşmanıza yardımcı olur. Çoğunlukla görüntüleme çalışmalarını okumak isteyen doktorlar için en önemli konu uygun görüntüleme istasyonunu seçmektir. Mükemmel okuma istasyonunun kullanıcı dostu olması ve iyi bir performans sağlaması gerekmektedir. Bu nedenle radyologlar için tasarlanmış olan kullanımı kolay bir okuma istasyonu niteliğindedir. Ortopedik cerrahlar, kiropraktörler, veterinerler ve dahiliye uzmanları gibi bütün doktorlar için geliştirilmiş olup gereksinimlere yönelik olarak optimize edilmiştir. Özel yazılım tasarımı nedeniyle Tmy Workstation çok hızlıdır.",
    },
    {
      title: "TmyPacs Teletıp",
      imgUrl: projImg5,
      popImg: projImg5,
      popText: "Medikal görüntülerin saklanması, geri çağrılması, dağıtımı ve sunumu için kullanılan yazılım ya da ağlara verilen isimdir. Görüntülerin, daha ekonomik ve daha portatif olan dijital depolama birimlerinde saklanabilmesi ve kullanıcıların istedikleri dosyalara çok daha hızlı ve etkili bir biçimde erişebilmesi amacıyla geliştirilmişlerdir. PACS sistemleri, geleneksel röntgen filmi bazlı çalışmanın sonucu olarak ortaya çıkan tüm fiziksel engelleri ve zaman kaybı sorunlarını ortadan kaldırmaktadır. Röntgen filmi görüntülerini ellerinde taşıma durumu artık hastalar için dert olmaktan çıkmakta, hastanedeki tüm birimler görüntülere tek bir merkez depolama ünitesi üzerinden ulaşabilmektedir. Medyalarında oluşan silik görüntü bozuklukları yok olmakta, hasta için konulacak tetkiklere çok daha hızlı karar verilebilmektedir. Medikal görüntülerin, PACS sistemlerinde saklanması için en çok kullanılan format DICOM (Digital Imaging and Communications in Medicine) formatıdır. TmyPacs yazılımı ile görüntüler tek bir merkezde toplandığı gibi E-Nabız sistemine gönderim de sağlayabilmektedir. E-Nabız bildirim başarımız %99'un üzerindedir.",
    },
    {
      title: "TmyPacs EEG",
      imgUrl: projImg6,
      popImg: contactPopup3,
      popText: "Alanında emsal niteliği taşıyan TmyPacs EEG yazılımı görüntülerinin raporlanması, saklanması, geri çağrılması ve sunumu için kullanılmaktadır. EEG görüntülerinin daha ekonomik ve portatif olan dijital depolama birimlerinde saklanabilmesi ve doktorların görüntülere çok daha hızlı ve etkili bir biçimde erişebilmesi amacıyla geliştirilmiştir.",
    },
    {
      title: "TmyPacs EPSON CD/DVD Robot Baskı",
      imgUrl: projImg1,
      popImg: contactPopup1,
      popText: "Tek Tuşla Mükemmel Sonuç Medikal CD Robotu; Medikal standartlara sahip yüksek hızlı bir cd robot sistemidir. Yoğun kullanımlar için optimize edilmiş sistem mimarisi ile saatte 60 CD-DVD üzeri medya yazılabilmektedir. Multi-modalite ve tüm PACS sistemleri ile çalışabilmesine olanak sağlayan DICOM uyumlulugu sayesinde sistem entegrasyonu çok hızlı ve kolay bir şekilde yapılabilmektedir. CD baskı dizaynı ve dicom görüntüleyici kullanıcının kurumsal kimligini yansıtacak sekilde özelleştirilebilir. Çekimden hemen sonra görüntülerin CD ortamında hastaya verilebilmesine imkan sağlar.",
    },
    {
      title: "TmyPacs OKI A3/A4 Dicom Kağıt Baskı",
      imgUrl: projImg2,
      popImg: projImg2,
      popText: "Medikal ekipmanlardan, dönüştürme yazılımı veya harici yazıcı sunucuları kullanmadan, direkt baskı almanıza imkan sağlayan, yüksek kaliteli çıktılar ve uygun maliyet sağlayan LED yazıcısı kombinasyonu, dijital baskı teknolojisinde bir ilktir. Röntgen filmleri(Ör: diş, veteriner, hastane, klinik) nükleer tıp, taramalar(Ör: MR, BT) ve ultrasonlar gibi görüntüleri, teşhis harici kullanım amacıyla hastalara veya müşterilerinize bir kopya vermek için, artık kolayca basabilirsiniz. Ayrıca, günlük A4/A3 ofis baskı ihtiyaçlarını; hasta mektuplarından tıbbi raporlara kadar, tek yazıcıyı seçerek kurumunuzun tüm ihtiyaçlarını karşılayabilen, aygıt sayısını ve sarf malzemeleri azaltan standart ofis yazıcısı olarak da kullanılabilir. Test edilmiş teknoloji , ödüllü yazıcı teknolojisi üzerine aynı sağlamlıkta oluşturulmuştur. Kullanımı kolay, sahip olma maliyeti düşük, medya esnekliği ve yüksek kaliteli çıktılara, ek fayda olarak dahili DICOM teknolojisi bulunan bir yazıcıdır.",
    },
    {
      title: "TmyPacs EPSON A3/A4 Dicom Kağıt Baskı",
      imgUrl: projImg4,
      popImg: projImg4,
      popText: "Bugünün dijital tanı yöntemleri (CR,CT,MR,US vb.) genellikle tıbbi görüntüleme dünyasında kullanılan bir iletişim standardı olan DICOM destekleyen özel ve pahalı kuru sistem film yazıcılara tıbbi görüntüleri yazdırmak hastaneler ve görüntüleme merkezleri için yüksek maliyetler getirmektedir. Tele Medikal Yazılım yüksek maliyetleri azaltmak amacı ile geliştirilen, Oki'nin yüksek çözünürlüğe sahip yazıcı ile renkli ya da gri ölçekli yüksek kalitede DICOM görüntülerini yazdırabilen baskı çözümüdür. Tüm ağ üzerindeki modalite, iş istasyonu veya Pacs yazılımlarından çıktı sağlayan kapsamlı kalibrasyon yapılabilen, sayfaya üstbilgi ve altbilgi, hastanın adı veya kurumun logosu konulabilen sistemdir. Geleneksel pahalı röntgen filmleri yerine, çeşitli kağıt türlerine (şeffaf filmler, parlak kağıtlar, yarısaydam filmler vb.) yüksek kaliteli görüntülerin uzun yıllar saklanabileceği, yüzde yüz dönüşümü olan çevreci,ekonomik baskı çözümüdür. Film yazıcılara oranla sayfa maliyetlerini düşüren en ekonomik sistemdir.",
    },
    {
      title: "TmyPacs Endoskopi",
      imgUrl: projImg7,
      popImg: projImg7,
      popText: "Sağlık Bakanlığı tarafından TELETIP Teleradyoloji sistemine Endoskopi görüntülerinin aktarımı istenmektedir.Sağlık kurumlarının büyük kısmında Endoskopi tetkikleri video ve jpg görüntü olarak üretilmektedir.Bu yapıdaki görsel imajlar belli bir çözüm aracılığıyla E-Nabız sistemine aktarılabilir. Tele Medikal Yazılım olarak Kurumların üretmiş olduğu Endoskopi .jpg görüntülerini hastaya ait demografik bilgileri ile birleştirerek Dicom 3.0 yapısına dönüştürüp Pacs sistemine aktararak E-Nabız sisteminden ulaşımını sağlayacak çalışmayı yapabilmektedir. Kurumların Endoskopi tetkiklerinde faturalandırma süreçlerine çözüm sağlayarak AR-GE çalışmasını tamamlamıştır.",
    },
       
  ];

  return (
    <section href="#projects" className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>{t("project")}</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <div>
                    </div>
                    {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">I</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">II</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">III</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p></p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p></p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
