import { Col, Row, Container } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import { Button, ButtonGroup } from "react-bootstrap";
import newsletter from "../assets/img/newsletter.svg"
import "../App.css"
import { useTranslation } from 'react-i18next';

export const Newsletter = () => {
const { t, i18n } = useTranslation();
  return (
    <section className="newsletter-bx" id="news">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={newsletter} alt="Quality" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h3>{t("quality")}</h3>
                  <ButtonGroup vertical>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/KaliteYonetimi/bgyssertifikasi.pdf"}>
                      BGYS SERTİFİKASI
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/KaliteYonetimi/BGYS.PLT.00.pdf"}>
                      BİLGİ GÜVENLİĞİ POLİTİKASI
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/KaliteYonetimi/KVKK-PL-02-Kisisel-verilerin-korunmasi-ve-isletilmesi-politikasi.pdf"}>
                      KİŞİSEL VERİLERİN KORUNMASI VE İŞLETİLMESİ POLİTİKASI
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer"href={process.env.PUBLIC_URL + "/KaliteYonetimi/KVKK-DOK-02-KVKK-tedarikci-calisan-aydinlatma-2022.pdf"}>
                      KVKK TEDARİKÇİ ÇALIŞAN AYDINLATMA
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/KaliteYonetimi/KVKK-FR-01-KVKK-Basvuru-Formu.pdf"}>
                      KVKK BAŞVURU FORMU
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/KaliteYonetimi/KVKK-PL-01-Cerez-politikasi.pdf"}>
                      ÇEREZ POLİTİKASI
                    </Button>
                  </ButtonGroup>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
