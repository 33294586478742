import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import {
  Card,
  CardBlock,
  CardFooter,
  CardTitle,
} from 'react-bootstrap-card';
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="footer" id="footer">
      <createMailtoLink></createMailtoLink>
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <Card>
              <CardBlock className="designer2">
                <CardTitle>
                  <h3>{t("footer-1")}</h3>
                  <h5>Tele Medikal Yazılım ve Bilişim Teknoloji Ürünleri San. ve Tic. LTD. ŞTİ.</h5>
                </CardTitle>
                {t("footer-2")}: TEKNOPOL Ahmet Yesevi Mah. Kerem Sk. A Blok No:9/106 Pendik, İstanbul
              </CardBlock>
              <CardFooter className="designer2">
              {t("footer-3")}: 0216 341 62 42
              </CardFooter>
              <CardFooter className="designer2">
              {t("footer-5")}: 0216 341 99 10
              </CardFooter>
              <CardFooter className="designer2">
              {t("footer-4")}: info@telemedikalyazilim.com
              </CardFooter>
            </Card>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://g.page/teknopolistanbul?share" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="gmaps" /></a>
              <a href="https://www.linkedin.com/company/telemedikal-yazılım/mycompany/" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="linkedin"/></a>
            </div>
            <p>Copyright 2023. All Rights Reserved.</p>
            <p>Designed By: <a rel="nofollow" href="https://www.telemedikalyazilim.com" className="designer">Tele Medikal Yazılım</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
