import { Container,Row  } from "react-bootstrap";
import '../App.css';

export const Iletisim = () => {
  return (
    <section className="iletisim" id="iletisim">
      <Container>
      <Row className="aligh-items-center">
        </Row>
        
      </Container>
    </section>
  )
}
