import { Container, Row, Col, } from "react-bootstrap";
import referenceImg from "../assets/img/references.gif";
import '../App.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';

export const FooterS = () => {
  const { t } = useTranslation();
  return (
    <section className="footers" id="footers">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={10} xl={10}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className="footersImg">
                  <h2>{t("referanslar")}</h2>
                  <img src={referenceImg} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>

      </Container>
    </section>
  )
}
