import { Container, Col, Row } from "react-bootstrap";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "../App.css";

export const ProjectCard = ({ title, imgUrl, popImg, popText }) => {

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <Container className="popup-bx">
            <Row className="align-items-center">
              <Col>
                <Popup modal trigger={<button className="proj-txtx"><p></p></button>} closeOnDocumentClick={true}>
                  {close => (
                    <>
                      <div className="popup">
                        <span>{popText}</span>
                        <img className="popupimg" src={popImg} alt="Popups" />
                      </div>
                    </>
                  )}
                </Popup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Col>
  )
}
