import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import '../App.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';
import {Button,ButtonGroup} from "react-bootstrap";
import cer1 from "../assets/doc/15637,medula-teletip-tele-radyoloji-e-nabizustyazi.pdf";
import cer2 from "../assets/doc/tmypacs-hl7-conformance2019.pdf";
import cer3 from "../assets/doc/IntegrationStatement.pdf";
import cer4 from "../assets/doc/tmypacs-conformance2019.pdf";
import cer5 from "../assets/doc/medicad.pdf";

export const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Sertifikalar" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>{t("contact")}</h2>
                  <ButtonGroup vertical>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={cer1}>
                    MEDULA TELETIP-TELE RADYOLOJİ e-NABIZ
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={cer2}>
                    TMYPACS HL7 CONFERMANCE
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={cer3}>
                    TMYPACS INTEGRATION STATEMENT
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={cer4}>
                    DICOM CONFORMANCE STATEMENT
                    </Button>
                    <Button className="designer" target="_blank" rel="noopener noreferrer" href={cer5}>
                    MEDICAD ORTOPEDİ ÇÖZÜMLERİ
                    </Button>
                  </ButtonGroup>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
